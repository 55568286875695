import { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import StoreContext from "../context/store";
import { DiscountContext } from "../context/DiscountProvider";
import { edgesToArray } from "../context/helpers";
import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentLang } from "../hooks/useLocalizedCartInfo";
import { useCurrentCountryCode } from "../hooks/usePrices";

import SEO from "../components/seo";
import ALCustomLandingPage from "../components/al_components/ALCustomLandingPage";
import { ComponentType } from "../constants/ComponentType";

function IndexPage({ data }) {
  const context = useContext(StoreContext);
  const { discountInfo } = useContext(DiscountContext);
  const lang = useCurrentLang();
  const countryCode = useCurrentCountryCode();
  const [saleColors, updateSaleColors] = useState(null);

  let contentHome = null;

  if (data && "homeContent" in data) {
    const allBlocsArray = edgesToArray(data.homeContent);
    const defaultContentHome = allBlocsArray.find(
      (n) => n?.countries?.some((c) => c.code === "RoW") && n.node_locale === "en-US"
    )?.blocs;
    const blocsArray = allBlocsArray.filter((n) =>
      n?.countries?.some((c) => c.code === countryCode)
    );
    const blocsRoot = blocsArray.find((b) => b.node_locale === lang);
    contentHome = blocsRoot?.blocs ?? defaultContentHome;
  }

  useEffect(() => {
    if (data?.saleColors?.edges) {
      const dataSaleColors = data?.saleColors?.edges.reduce((acc, col) => {
        acc[col.node.discountCode] = col.node;
        return acc;
      }, {});
      dataSaleColors.default = {
        hpBgColorDesktop: "#E4E1DB",
        hpBgColorMobile: "#E4E1DB",
        hpButtonBgColorDesktop: "#000000",
        hpButtonBgColorMobile: "#000000",
        hpButtonHoverBgColorDesktop: "#FFFFFF",
        hpButtonHoverBgColorMobile: "#FFFFFF",
        hpTextColorDesktop: "#FFFFFF",
        hpTextColorMobile: "#FFFFFF",
      };
      updateSaleColors(dataSaleColors[discountInfo?.code] || null);
      context.updateSalesColor(dataSaleColors);
    }
  }, []);

  return (
    <>
      <SEO title="home" />
      <Helmet>
        <link rel="canonical" href="https://www.analuisa.com/" />
        <meta property="og:url" content="https://www.analuisa.com/" />
      </Helmet>
      <ALCustomLandingPage
        data={{ blocks: contentHome }}
        saleColors={saleColors}
        componentType={ComponentType.HP}
      />
    </>
  );
}

export default withALErrorBoundary({
  name: "IndexPage",
  priority: "P1",
})(IndexPage);

export const query = graphql`
  query {
    # fullpage
    saleColors: allContentfulSaleColor {
      edges {
        node {
          discountCode
          hpButtonBgColorDesktop
          hpBgColorDesktop
          hpButtonHoverBgColorDesktop
          hpTextColorDesktop
          hpBgColorMobile
          hpButtonBgColorMobile
          hpButtonHoverBgColorMobile
          hpTextColorMobile
        }
      }
    }
    homeContent: allContentfulHomePageBlocs(filter: { isActive: { eq: true } }) {
      edges {
        node {
          id
          node_locale
          countries {
            code
          }
          blocs {
            ...ContentfulLandingPageBlocks
          }
        }
      }
    }
  }
`;
